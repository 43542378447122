import {defineStore} from 'pinia'

export type MenuState = {
    menu: {
        records: any
    },
    loading: Boolean,
    error: string | any
};

export const useMenuStore = defineStore({
    id: 'menu',
    state: () => ({
        menu: {
            records: []
        },
        loading: false,
        error: ''
    } as MenuState),

    getters: {
        baseMenu(state) {
            return state?.menu.records.find((item: any) =>
                item.fields['Название'] === 'Блюда')?.fields['PDF']?.[0]?.url
        },
        wineMenu(state) {
            return state?.menu.records.find((item: any) =>
                item.fields['Название'] === 'Напитки')?.fields['PDF']?.[0]?.url
        }
    },
    actions: {
        fetchMenu: async function () {
            // this.slides = null
            this.loading = true
            try {
                this.menu = await $fetch('/api/menu')
            } catch (err) {
                this.error = err
            } finally {
                this.loading = false
            }
        }
    }
})
