import {defineStore} from 'pinia'

export type SlidesState = {
    slides: {
        records: any
    },
    loading: Boolean,
    error: string | any
};

export const useSlidesStore = defineStore({
    id: 'slides',
    state: () => ({
        slides: {
            records: []
        },
        loading: false,
        error: ''
    } as SlidesState),

    getters: {
        kitchenSlides(state) {
            return state?.slides.records.find((item: any) => item.fields['Название'] === 'Кухня')?.fields['Изображения']
        },
        atmosferaSlides(state) {
            return state?.slides.records.find((item: any) => item.fields['Название'] === 'Атмосфера')?.fields['Изображения']
        },
        banketSlides(state) {
            return state?.slides.records.find((item: any) => item.fields['Название'] === 'Банкеты')?.fields['Изображения']
        },
        promoSlides(state) {
            return state?.slides.records.find((item: any) => item.fields['Название'] === 'Акции')?.fields['Изображения']
        },
        eventsSlides(state) {
            return state?.slides.records.find((item: any) => item.fields['Название'] === 'Афиша')?.fields['Изображения']
        },
    },
    actions: {
        fetchSlides: async function () {
            // this.slides = null
            this.loading = true
            try {
                this.slides =await $fetch('/api/slides')
            } catch (err) {
                this.error = err
            } finally {
                this.loading = false
            }
        }
    }
})
