import {defineStore} from 'pinia'

export type SettingsState = {
    settings: {
        records: any
    },
    loading: Boolean,
    error: string | any
};

export const useSettingsStore = defineStore({
    id: 'settings',
    state: () => ({
        settings: {
            records: []
        },
        loading: false,
        error: ""
    } as SettingsState),

    getters: {
        phone(state) {
            return state.settings?.records[0]?.fields?.['Телефон'] || "+7 (966) 163-99-99"
        },
        phoneHref() {
          return this.phone.replace(
              /[`~!@#$%^&*()_|\-=?;:'",.<>\{\}\[\]\\\/, " "]/g,
              '')
        },
        email(state) {
            return state.settings?.records[0]?.fields?.Email || "btwmsk@gmail.com"
        },
        city(state) {
            return state.settings?.records[0]?.fields?.['Город'] || "Москва"
        },
        address(state) {
            return state.settings?.records[0]?.fields?.['Адрес'] || "Пречистенка 40/2, ст. 2"
        },
        workTime(state) {
            return state.settings?.records[0]?.fields?.['Часы работы'] || "с 12 до 23"
        },
        workDays(state) {
            return state.settings?.records[0]?.fields?.['Дни работы'] || "Ежедневно"
        },
        instagram(state) {
            return state.settings?.records[0]?.fields?.Instagram || "https://www.instagram.com/gastrobar.btw/"
        },
        latitude(state) {
            return state.settings?.records[0]?.fields?.['Широта'] || "55.739202"
        },
        longitude(state) {
            return state.settings?.records[0]?.fields?.['Долгота'] || "37.587330"
        },
        chatIds(state) {
            const ids = state.settings?.records[0]?.fields?.TELEGRAM_CHAT_IDS || '';
            return ids.split(',');
        }
    },
    actions: {
        fetchSettings: async function () {
            // this.settings = null
            this.loading = true
            const options = {
                table: 'Настройки',
                fields: ['Город', 'Адрес', 'Телефон', 'Дни работы', 'Часы работы', 'Email', 'Instagram', 'Широта', 'Долгота', 'TELEGRAM_CHAT_IDS'],
                maxRecords: '1',
                filterByFormula: '{Сайт}="btwine.ru"'
            }
            try {
                this.settings = await $fetch('/api/settings')
            } catch (error) {
                this.error = error
            } finally {
                this.loading = false
            }
        }
    }
})
