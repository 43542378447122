import { defineStore } from "pinia";

export type LayoutState = {
    showMobileNav: Boolean
};

export const useLayoutStore = defineStore({
    id: 'layout',
    state: () => ({
        showMobileNav: false
    } as LayoutState),

    getters: {},
    actions: {
        toggleMobileNav: function () {
            this.showMobileNav = !this.showMobileNav;
            if (this.showMobileNav) {
                document.documentElement.classList.add("no-scrolled");
            } else {
                document.documentElement.classList.remove("no-scrolled");
            }
        },
        closeMobileNav: function () {
            this.showMobileNav = false;
            document.documentElement.classList.remove("no-scrolled");
        }
    }
})
